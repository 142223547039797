import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../api/api';
import apiUtil from '../../api/apiUtil';
import { Button, Card } from 'react-bootstrap';
import successImage from '../../assets/success.gif';
import '../../styles/PaymentSuccess.css';
import { Link, useLocation } from 'react-router-dom';
import Loading from '../../assets/loading.gif'

const PaymentSuccess = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderNumber = queryParams.get('order_number');
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    callReadApi();
  }, []);

  const callReadApi = useCallback(() => {
    setIsLoading(true);
    const getData = Api.details('orders', orderNumber);

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        setOrder(data.data);
        setIsLoading(false);
        console.log("data", data.data)
      }, (error) => {
        console.error('Error fetching order data', error);
        setIsLoading(false);
      });
    });
  }, [orderNumber]);

  if (isLoading || !order) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{minHeight: '400px'}}>
      <img src={Loading} className="img-fluid" style={{objectFit: 'cover', width : '300px', height: '300px'}} />
    </div>;
  }

  return (
    <div className="payment-success-container">
      <Card className="text-center mb-4 pb-4">
        <Card.Body className="text-center">
          <img src={successImage} alt="Success" className="success-icon" />
          <Card.Title className="text-center w-100"><div className="text-center w-100">Thank you for your purchase! </div></Card.Title>
          <Card.Text>
             <div className="text-center"> 
            <div className='fs-6 mb-2'>Hey, {order.customer_name || order.customer_email}, just want to drop a quick note to express our genuine gratitude. Your purchase allows us at SmartStickies to continue to do what we love and provide you with quality products.</div>

            <div className='fs-6 mb-2'>We are honored to have you as a customer and hope to serve you for a long time.</div>
             </div>
          </Card.Text>
          <div className="button-group">
          <Link to={"/view-order/" + orderNumber} ><Button variant="outline-primary" className="mx-2">View Order</Button></Link>
            <Link to="/" ><Button variant="primary" className="mx-2">Continue Shopping</Button></Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
