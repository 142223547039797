import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import Api from '../../api/api';
import apiUtil from '../../api/apiUtil';
import '../../styles/ViewOrder.css'; // Assuming you have custom styles
import Loading from '../../assets/loading.gif'
import NoOrder from '../../assets/no_order.png'
import { useCompany } from '../../contexts/CompanyContext';

const SearchOrder = () => {

  const { companyId, companyData } = useCompany();

  const [order, setOrder] = useState(null);
  const [orderNum, setOrderNum] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // callReadApi();
  }, []);

  const callReadApi = useCallback((order_number = '') => {
    setIsLoading(true);
    const getData = Api.details('orders', order_number);

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        setOrder(data.data);
        setIsLoading(false);
        console.log("data", data.data)
      }, (error) => {
        console.error('Error fetching order data', error);
        setIsLoading(false);
      });
    });
  }, []);

  if (isLoading) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{minHeight: '400px'}}>
      <img src={Loading} className="img-fluid" style={{objectFit: 'cover', width : '300px', height: '300px'}} />
    </div>;
  }

  if (!order) {
    return <div className="container text-center my-5 d-flex flex-column justify-content-center align-items-center w-100" style={{minHeight: '400px'}}>
      <form className="row w-100 d-flex align-items-center justify-content-center">
        <div class="col-12 col-lg-8 d-flex align-items-center justify-content-center">
          <label for="orderNumberInput" className='w-25'>Order Number</label>
          <input type="text" 
            value={orderNum}
            onChange={e=>{
              setOrderNum(e.target.value)
            }}
          class="form-control w-75 py-2" id="orderNumberInput" placeholder="Enter Order Number" />
          <button onClick={(e)=> {
            callReadApi(orderNum)
          }} style={{backgroundColor: companyData && companyData.primary_color, borderColor: companyData && companyData.primary_color}} class="btn btn-primary ms-4" disabled={!orderNum && true}>Search Order</button>
        </div>
      </form>

       <img src={NoOrder} className="img-fluid" style={{objectFit: 'cover', width : '300px', height: '300px'}} />
    </div>;
  }

  const { order_number: orderNumber, customer_email, sub_total, tax, total, payment_status, order_products } = order;

  return (
   
    <div className="my-4 container">
      <form className="row w-100 d-flex align-items-center justify-content-center mb-4">
        <div class="col-12 col-lg-8 d-flex align-items-center justify-content-center">
          <label for="orderNumberInput" className='w-25'>Order Number</label>
          <input type="text" 
            value={orderNum}
            onChange={e=>{
              setOrderNum(e.target.value)
            }}
          class="form-control w-75 py-2" id="orderNumberInput" placeholder="Enter Order Number" />
          <button onClick={()=> {
            callReadApi(orderNum)
          }} style={{backgroundColor: companyData && companyData.primary_color, borderColor: companyData && companyData.primary_color}} class="btn btn-primary ms-4" disabled={!orderNum && true}>Search Order</button>
        </div>
      </form>
      <div className='card'>
        <div className='card-header'>
          <h4>Order Details</h4>
        </div>
        <div  className='row'>
          <div className='col-12 col-lg-6'>
          <ul class="list-group card-body">
              <li class="list-group-item">
                <div class="d-flex justify-content-between">
                  <div class="text-start"><strong>Order Number:</strong></div>
                  <div class="text-end">{orderNumber}</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between">
                  <div class="text-start"><strong>Customer Email:</strong></div>
                  <div class="text-end"> {customer_email}</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between">
                  <div class="text-start"><strong>Subtotal:</strong></div>
                  <div class="text-end">${sub_total}</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between">
                  <div class="text-start"><strong>Tax:</strong></div>
                  <div class="text-end"> ${tax}</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between">
                  <div class="text-start"><strong>Total:</strong></div>
                  <div class="text-end">${total}</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex justify-content-between">
                  <div class="text-start"><strong>Payment Status:</strong></div>
                  <div class="text-end"> {payment_status ? 'Paid' : 'Unpaid'}</div>
                </div>
              </li>
            </ul>
          </div>
          <div className='col-12 col-lg-6 card-body'>
              <h5>Order Products</h5>
              {order_products.map(product => (
                <div className="mb-3" key={product.id}>
                <div className='row p-4'>

                <div className='col-12 col-lg-4'>

                  <Link to={`/${product.id}`}>
                    <img src={product.ecommerce_product.image_url} className="img-fluid border rounded" style={{objectFit: 'cover', width : '200px', height: '200px'}} />
                  </Link>

                </div>
                <div className='col-12 col-lg-8 d-flex flex-column align-items-between justify-content-between'>
                    <div>
                      <div className='fw-medium fs-3 mt-0'> {product.ecommerce_product.name} </div>
                      <p className='fs-4'>${product.product_price} ( <span>Total Price:</span> ${product.product_total_price} ) </p>
                      
                    </div>
                    <div className='bg-black text-white rounded w-25'>
                      <div className='d-flex align-items-center justify-content-evenly'>
                        <span className='fw-bold fs-2 text-white'> {product.product_quantity} </span>
                      </div>
                    </div>
                </div>
                </div>
                <hr />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOrder;
