import React from 'react'
import "../styles/HeroSection.css"
import one from "../assets/innovation.png"
import two from "../assets/Quality.png"
import three from "../assets/userCentric.png"
import Loading from '../assets/loading.gif'
import { useCompany } from '../contexts/CompanyContext';

const HeroSection = () => {
    const { companyId, companyData, isLoadingCompanyData } = useCompany();

    if (isLoadingCompanyData) {
        return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
            <img src={Loading} className="img-fluid" style={{ objectFit: 'cover', width: '300px', height: '300px' }} />
        </div>;
    }

    return (
        <div className='container-fluid'>
            <div className='row d-flex justify-content-center align-items-start'>
                <div className='pt-4 col-12'>
                <p className='text-center fw-bold fs-2' style={{color: companyData && companyData.title_color}}>{companyData?.section_title != null && companyData.section_title != 'null' ? companyData.section_title : 'Welcome to our store!'}</p>
                </div>
                <div className='pt-4 col-12 w-100 text-center'>
                    <p className='text-xl text-center' style={{color: companyData && companyData.desc_color}}>
                        {companyData && companyData.section_desc != null && companyData.section_desc != 'null' ? companyData.section_desc : ''}
                    </p>
                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={companyData && companyData.section_image_1} className="mb-2" style={{width: '80%'}} />
                    <p className='fw-bold' style={{color: companyData && companyData.sub_title_color}}> {companyData && companyData.section_title_1}</p>
                    <p className='text-center px-4 mx-3' style={{color: companyData && companyData.sub_desc_color}}>
                         {companyData && companyData.section_desc_1}
                    </p>
                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={companyData && companyData.section_image_2} className="mb-2" style={{width: '80%'}} />
                    <p className='fw-bold' style={{color: companyData && companyData.sub_title_color}}>{companyData && companyData.section_title_2}</p>
                    <p className='text-center px-4 mx-3' style={{color: companyData && companyData.sub_desc_color}}>
                         {companyData && companyData.section_desc_2}
                    </p>

                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={companyData && companyData.section_image_3} className="mb-2" style={{width: '80%'}} />
                    <p className='fw-bold' style={{color: companyData && companyData.sub_title_color}}>{companyData && companyData.section_title_3}</p>
                    <p className='text-center px-4 mx-3' style={{color: companyData && companyData.sub_desc_color}}>
                         {companyData && companyData.section_desc_3}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HeroSection