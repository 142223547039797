import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { store } from '../productsStore/Store'
import "../styles/BestSellers.css";
import HoverImage from 'react-hover-image/build';
import { Link } from 'react-router-dom';
import Api from "../api/api"
import apiUtil from '../api/apiUtil';
import Loading from '../assets/loading.gif'
import { FaShoppingBag } from "react-icons/fa";
import { useCompany } from '../contexts/CompanyContext';

const BestSellers = () => {
  const { companyId, companyData } = useCompany();
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [discount, setDiscount] = useState(null);

  useEffect(() => {
    callReadApi();
  }, [])

  const callReadApi = useCallback(() => {

    let param = {
      company_id: companyId
    };

    setIsLoading(true);
    const getData = Api.read('products', param)

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = (data.data && data.data.length > 0) ? data.data : [];
        const discountData = data.discount || null;

        const transformedData = dataArr.map((product) => {
          const discountedPrice = calculateDiscountPrice(product.price, discountData);
          return {
            id: product.id,
            name: product.name,
            price: discountedPrice,
            originalPrice: product.price,
            type: 'bestSeller',
            primaryImage: product.image_url,
            hoverImg: product?.images?.length > 0 ? product.images[0].file_url : product.image_url,
            gallery_images: product.images,
            description: product.description,
            quantity: product.quantity,
            stripeDiscountId: discountData ? discountData.stripe_coupon_id : null, // For Discount
          };
        });

        setData(transformedData);
        setDiscount(discountData);
        setIsLoading(false);

      }, (error, type) => {
        setData([]);
        setIsLoading(false);
      });
    });
  }, [])

  const calculateDiscountPrice = (price, discountData) => {
    if (!discountData) return price;

    const { discount_type, amount } = discountData;

    // Calculate based on discount type (e.g., 'percent' or 'fixed')
    if (discount_type === 'percent') {
      return price - (price * amount) / 100;
    } else if (discount_type === 'fixed') {
      return price - amount;
    }

    return price; // If no discount type matches, return the original price
  };

  if (isLoading) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      <img src={Loading} className="img-fluid" style={{ objectFit: 'cover', width: '300px', height: '300px' }} />
    </div>;
  }

  return (
    <div className='row p-4'>
      {/* {data.map((item) => {
        if (item.type == "bestSeller")
          return (
            <Link to={`/${item.id}`} key={item.id} className="col-12 col-lg-3">  
            <div key={item.id} className='d-flex flex-column justify-content-center align-items-center'>
              <HoverImage src={item.primaryImage} hoverSrc={item.hoverImg} className="img-fluid border rounded rounded-xl mb-6" 
              style={{objectFit: 'cover', width: '200px', height: '200px'}}/>
              <p className='bestSellerName text-center mb-2'> {item.name} </p>
              <p className=' font-normal text-center'> ${parseFloat(item.price).toFixed(2)} </p>
            </div>
            </Link>
          )
      })} */}

      {data
        .filter(item => item.type === "bestSeller")
        .sort((a, b) => b.id - a.id)
        .slice(0, 4)
        .map(item => (
          <Link to={`/${item.id}`} key={item.id} className="col-12 col-lg-3">
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <HoverImage
                src={item.primaryImage}
                hoverSrc={item.hoverImg}
                className="img-fluid border rounded rounded-xl mb-6"
                style={{ objectFit: 'cover', width: '200px', height: '200px' }}
              />
              <p className='bestSellerName text-center mb-2'> {item.name} </p>

              {item.originalPrice && item.originalPrice !== item.price ? (
                <>
                    <h2 className="text-lg text-muted mb-2">
                    <del>${parseFloat(item.originalPrice).toFixed(2)}</del>
                    </h2>
                    <h2 className="text-xl mb-2 text-success">${parseFloat(item.price).toFixed(2)}</h2>
                </>
              ) : (
                <h2 className="text-xl mb-2">${parseFloat(item.price).toFixed(2)}</h2>
              )}
              {/* <p className='font-normal text-center'> ${parseFloat(item.price).toFixed(2)} </p> */}
            </div>
          </Link>
        ))}
      <hr />
      <Link to={`/catalog`} className="px-4 col-12 col-lg-12 d-flex justify-content-end align-items-center">
        <a className='fs-6 d-flex align-items-center' style={{color: companyData && companyData.primary_color}}><FaShoppingBag /> &nbsp; &nbsp; Shop all items</a>
      </Link>
    </div>
  )
}

// {originalPrice && originalPrice !== price ? (
//   <>
//       <h2 className="text-lg text-muted mb-2">
//       <del>${parseFloat(originalPrice).toFixed(2)}</del>
//       </h2>
//       <h2 className="text-xl mb-2 text-success">${parseFloat(price).toFixed(2)}</h2>
//   </>
// ) : (
//   <h2 className="text-xl mb-2">${parseFloat(price).toFixed(2)}</h2>
// )}
export default BestSellers;
