import React from 'react'
import { AiFillStar } from "react-icons/ai"
import feel from "../assets/feel.png";


const Review1 = (props) => {
    const starCalc = props.starCalc;
    const reviewes = props.rev;
    const oneDigit = Math.floor(Math.random() * 10);

    return (
        <div>

           

            {/* <p className='text-gray-300 mb-4'> _____________________________________________________________________________________________________________________________________________________________________________________      </p> */}
           
           <hr />
            <div className='flex gap-5 justify-center flex-row fof mt-2'>
                <p className="text-gray-500 text-4xl font-semibold revSize"> {starCalc ? parseFloat(starCalc).toFixed(2) : 0.00} </p>
                {starCalc === 1 ? <div className='flex fs30 starLine'>  <AiFillStar /> </div> : ""}
                {starCalc === 2 ? <div className='flex fs30 starLine'> <AiFillStar /> <AiFillStar />  </div> : ""}
                {starCalc === 3 ? <div className='flex fs30 starLine'> <AiFillStar /> <AiFillStar /> <AiFillStar />  </div> : ""}
                {starCalc === 4 ? <div className='flex fs30 starLine'> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar />  </div> : ""}
                {starCalc === 5 ? <div className='flex fs30 starLine'> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar />  </div> : ""}
                <p className='starLine2'> {reviewes} Reviews </p>
            </div>
            <div className='mt-2 d-flex justify-content-center'>
                <img src={feel} className="feelImg" />
            </div>
        </div>
    )
}

export default Review1