import React, {useState, useEffect, useRef, useCallback, useContext} from 'react'
import { store } from "../productsStore/Store";
import OurBestSellers from './OurBestSellers';
import Api from "../api/api"
import apiUtil from '../api/apiUtil';
import { Link } from 'react-router-dom';
import { FaShoppingBag } from "react-icons/fa";
import { useCompany } from '../contexts/CompanyContext';

const Catalog = () => {
    const { companyId } = useCompany();

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [discount, setDiscount] = useState(null);

    useEffect(() => {
        callReadApi();
      }, [])
    
      const callReadApi = useCallback(() => {
    
        let param = {
            company_id: companyId
        };
        
          setIsLoading(true);
          const getData = Api.read('products', param)
          
          Promise.all([getData]).then( (results) => {
              apiUtil.parseResult(results[0], (data) => {
                const dataArr = (data.data && data.data.length > 0) ? data.data : [];
                const discountData = data.discount || null;

                const transformedData = dataArr.map((product) => {
                  const discountedPrice = calculateDiscountPrice(product.price, discountData);
                  return {
                      id: product.id,
                      name: product.name,
                      price: discountedPrice,
                      originalPrice: product.price,
                      type: 'ourBestSellers',
                      primaryImage: product.image_url,
                      hoverImg: product?.images?.length > 0 ? product.images[0].file_url : product.image_url,
                      gallery_images: product.images,
                      description: product.description,
                      quantity: product.quantity,
                      stripeDiscountId: discountData ? discountData.stripe_coupon_id : null,
                  };
              });

                setData(transformedData);
                setDiscount(discountData);
                setIsLoading(false);
    
                console.log('data ds', dataArr, transformedData)
              }, (error, type) => {
                setData([]);
                setIsLoading(false);
              });
          });
      }, [])

    const calculateDiscountPrice = (price, discountData) => {
        if (!discountData) return price;
        const { discount_type, amount } = discountData;
        if (discount_type === 'percent') {
            return price - (price * amount) / 100;
        } else if (discount_type === 'fixed') {
            return price - amount;
        }
        return price;
    };

    return (
        <div className='container-fluid mb-4'>
            <div className='row w-100 mt-4 p-0 m-0'>
                {data && data.map(((item) => {
                        return (
                            <OurBestSellers
                                key={item.id}
                                id={item.id}
                                title={item.name}
                                price={item.price}
                                originalPrice={item.originalPrice}
                                stripeDiscountId={item.stripeDiscountId}
                                image={item.primaryImage}
                            />
                        )
                }))}
            </div>
        </div>
    )
}

export default Catalog