import React, { Component } from "react";
import Slider from "react-slick";
import { store } from "../productsStore/Store";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import api from "../api/api";
import apiUtil from "../api/apiUtil";

export default class AutoPlayMethods extends Component {
    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.state = {
            data: [],
            isLoading: false,
        }
    }

    componentDidMount() {
       this.callReadApi();
    }

    callReadApi = () => {
        const { companyId } = this.props;
        this.setState({
          isLoading: true
        }, () => {
          let param = {
            sort: "latest",
            company_id: companyId,
          };
          
          
          // if(order)
          api.read('products', param)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {

                const dataArr = (data.data && data.data.length > 0) ? data.data : []
                const transformedData = dataArr && dataArr.map(product => ({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    type: 'ourBestSellers', // example logic for type
                    primaryImage: product.image_url,
                    hoverImg: product?.images?.length > 0 ? product.images[0].file_url : product.image_url,
                    gallery_images: product.images,
                    description: product.description,
                    quantity: product.quantity,
                  }));

                this.setState({
                  data: transformedData,
                  isLoading: false,
                });
    
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  uploaders: [],
                  isLoading: false,
                });
              });
          });
        });
      }

    play() {
        this.slider.slickPlay();
    }
    pause() {
        this.slider.slickPause();
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500,
            arrows: false,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        };
        return (
            <div className="mt-4 pt-4">
                <Marquee pauseOnHover={true} speed={100} >
                    {this.state.data.map((item) => {
                        if (item.type == "ourBestSellers") {
                            return (<div key={item.id} className="mx-4 border rounded h-100">
                                <Link to={`/${item.id}`}>
                                    <img src={item.primaryImage} className=" w-48 spCaroImg brForMobile rounded-xl p-4 " />
                                </Link>
                            </div>)
                        }
                    })}
                </Marquee>

            </div>
        );
    }
}



