import React from 'react'

const CatalogText = () => {
    return (
        <div>

            <p className='fs-3 fw-bold text-center mt-4'> OUR CATALOGS </p>

        </div>
    )
}

export default CatalogText