import React from 'react'
import { store } from '../productsStore/Store'
import "../styles/OurBestSellers.css"
import { useDispatch } from 'react-redux'
import { cartActions } from '../redux-state/CartState'

import { Link } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { useCompany } from '../contexts/CompanyContext';

const OurBestSellers = (props) => {

  const { companyId, companyData } = useCompany();

  const { title, price, stripeDiscountId, originalPrice, id, image } = props;

  const dispacth = useDispatch();

  const addItemToCartHandler = (e) => {

    dispacth(
      cartActions.addItemToCart({
        id,
        price,
        stripeDiscountId,
        originalPrice,
        title,
        image,
      })
    )
  }

  const toast = useToast();

  const truncateText = (text, maxLength = 16) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };


  return (
    <div className='col-12 col-lg-3 flex justify-center align-items-start mb-4'>
      <div className="card h-full shadow-xl rounded-xl" >

        <Link to={`/${id}`}>
          <figure className="px-10 md:px-3 xl:px-10 pt-10">
            <img src={image} alt="product images" className="rounded-xl" style={{ objectFit: 'cover', width: '100%', height: '250px' }} />
          </figure>

        </Link>
        <div className="card-body items-center text-center">
          <h2 className="mb-1 fw-bold fs-5" >{truncateText(title)}</h2>
          {/* <h2 className=" text-xl mb-2 fof">${parseFloat(price).toFixed(2)}</h2> */}

          {originalPrice && originalPrice !== price ? (
            <>
              <h2 className="text-lg text-muted mb-2">
                <del>${parseFloat(originalPrice).toFixed(2)}</del>
              </h2>
              <h2 className="text-xl mb-2 text-success">${parseFloat(price).toFixed(2)}</h2>
            </>
          ) : (
            <h2 className="text-xl mb-2">${parseFloat(price).toFixed(2)}</h2>
          )}


          <div className="card-actions" onClick={() =>
            toast({
              title: '',
              description: "Successfully Added",
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          }>
            <button style={{ backgroundColor: companyData && companyData.primary_color, borderColor: companyData && companyData.primary_color }} className="btn btn-primary" onClick={addItemToCartHandler}>Buy Now</button>
          </div>

        </div>

      </div>
    </div>
  )
}

export default OurBestSellers
