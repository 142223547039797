import React from 'react';
import { useSelector } from 'react-redux';
import StripeCheckoutButton from './stripe/StripeCheckOut';
import CartAdditonalFeatures from './CartAdditonalFeatures';
import Features2 from './Features2';

const CartTotal = (props) => {
    let cartItems = useSelector((state) => state.cart.items);

    let cartLen = cartItems.length;


    return (
        <div className='mt-4 pt-4 px-4'>
            <div className='d-flex align-items-center justify-content-between mt-4 pt-4 px-4'>
                <p className='fw-medium fs-3'> SUBTOTAL </p>
                <p className='fw-medium fs-3'> ${parseFloat(props.totalPr).toFixed(2)} </p>
            </div>

            {props.discountTotal > 0 && (
                <div className='d-flex align-items-center justify-content-between pt-2 px-4'>
                    <p className='fs-5'>DISCOUNT </p>
                    <p className='fs-5 text-danger'>- ${parseFloat(props.discountTotal).toFixed(2)} </p>
                </div>
            )}

            <StripeCheckoutButton cartItems={cartItems} />

            {cartLen > 1 ? <div className='mobDisappear mt-4'>

                <p className='relative fof fs-5 text-center top-9'> SECURELY CHCECKOUT WITH </p>

                <CartAdditonalFeatures />

                {cartLen > 2 ? <Features2 /> : ""}

            </div>
                :
                ""
            }

        </div>
    )
}

export default CartTotal;
