import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import Api from "../api/api";
import apiUtil from '../api/apiUtil';
import { useCompany } from '../contexts/CompanyContext';

const SPFooter = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { companyId, companyData } = useCompany();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    callReadApi();
    callSocialApi();
  }, []);

  const callReadApi = useCallback(() => {
    let param = {
      type: "footer",
      company_id: companyId,
    };

    setIsLoading(true);
    const getData = Api.read('tab', param);

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = data.data ? data.data : [];
        setTabs(dataArr);
        setIsLoading(false);
      }, (error, type) => {
        setTabs([]);
        setIsLoading(false);
      });
    });
  }, [companyId]);

  const callSocialApi = useCallback(() => {
    let param = {
      type: "footer",
      company_id: companyId,
    };

    setIsLoading(true);
    const getData = Api.read('social-medias', param);

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const settings = data.data ? JSON.parse(data.data[0].settings) : [];
        setData(settings);
        setIsLoading(false);
      }, (error, type) => {
        setData([]);
        setIsLoading(false);
      });
    });
  }, [companyId]);

  return (
    <div className='fotBg' style={{backgroundColor: companyData && companyData.primary_color, borderColor: companyData && companyData.primary_color}}>
      <footer className="footer p-10 text-xl text-base-content">
        {tabs.map((section) => (
          <div key={section.id}>
            <span className="footer-title text-white">{section.name}</span>
            {section.tabs && section.tabs.length > 0 ? (
              section.tabs.map((tab) => (
                <span
                  key={tab.id}
                  onClick={() => navigate(`/pages/${tab.name.toLowerCase()}`, { state: { id: tab.id } })}
                  className="link link-hover text-white cursor-pointer"
                >
                  {tab.name}
                </span>
              ))
            ) : (
              <p className='text-white'>No {section.name} available</p>
            )}
          </div>
        ))}
      </footer>
      <div className='px-4'>
        <hr className='text-white' />
      </div>
      <footer className="footer px-10 py-4">
      <div className="items-center grid-flow-col">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
            <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
          </svg>
          <p className='font-bold'>BUILT BY SMARTSTICKIES. <br /></p>
        </div>
        <div className="md:place-self-center md:justify-self-end">
          <div className="grid grid-flow-col gap-4">
            {data.map((social, index) => (
              social.url && (
                <a key={index} href={social.url} target="_blank" rel="noopener noreferrer">
                
                  {social.name === 'facebook' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                      <path d="M12 2.04c-5.5 0-9.96 4.46-9.96 9.96 0 4.93 3.58 9.01 8.3 9.88v-6.98h-2.51v-2.89h2.51v-2.2c0-2.5 1.49-3.87 3.77-3.87 1.1 0 2.25.2 2.25.2v2.47h-1.27c-1.25 0-1.64.78-1.64 1.57v1.83h2.79l-.45 2.89h-2.34v6.98c4.72-.88 8.3-4.96 8.3-9.88 0-5.5-4.46-9.96-9.96-9.96z"/>
                    </svg>
                  )}
                  {social.name === 'instagram' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                      <path d="M12 2.16c3.2 0 3.58.01 4.85.07 1.35.06 2.67.29 3.67 1.29 1 1 1.23 2.32 1.29 3.67.06 1.27.07 1.65.07 4.85s-.01 3.58-.07 4.85c-.06 1.35-.29 2.67-1.29 3.67-1 1-2.32 1.23-3.67 1.29-1.27.06-1.65.07-4.85.07s-3.58-.01-4.85-.07c-1.35-.06-2.67-.29-3.67-1.29-1-1-1.23-2.32-1.29-3.67-.06-1.27-.07-1.65-.07-4.85s.01-3.58.07-4.85c.06-1.35.29-2.67 1.29-3.67 1-1 2.32-1.23 3.67-1.29 1.27-.06 1.65-.07 4.85-.07m0-2.16c-3.26 0-3.67.01-4.95.07-1.56.07-2.96.33-4.03 1.41-1.08 1.08-1.34 2.47-1.41 4.03-.06 1.28-.07 1.69-.07 4.95s.01 3.67.07 4.95c.07 1.56.33 2.96 1.41 4.03 1.08 1.08 2.47 1.34 4.03 1.41 1.28.06 1.69.07 4.95.07s3.67-.01 4.95-.07c1.56-.07 2.96-.33 4.03-1.41 1.08-1.08 1.34-2.47 1.41-4.03.06-1.28.07-1.69.07-4.95s-.01-3.67-.07-4.95c-.07-1.56-.33-2.96-1.41-4.03-1.08-1.08-2.47-1.34-4.03-1.41-1.28-.06-1.69-.07-4.95-.07zm0 5.84c-3.4 0-6.16 2.76-6.16 6.16s2.76 6.16 6.16 6.16 6.16-2.76 6.16-6.16-2.76-6.16-6.16-6.16zm0 10.32c-2.3 0-4.16-1.87-4.16-4.16s1.87-4.16 4.16-4.16 4.16 1.87 4.16 4.16-1.87 4.16-4.16 4.16zm6.41-11.85c-.8 0-1.45-.65-1.45-1.45s.65-1.45 1.45-1.45 1.45.65 1.45 1.45-.65 1.45-1.45 1.45z"/>
                    </svg>
                  )}
                  {social.name === 'youtube' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                      <path d="M19.615 3.184c-3.6-.246-11.63-.245-15.23 0-3.9.266-4.36 2.62-4.39 8.82.03 6.18.49 8.55 4.39 8.82 3.6.245 11.63.246 15.23 0 3.9-.266 4.36-2.62 4.39-8.82-.03-6.18-.49-8.55-4.39-8.82zm-10.62 12.82v-8l8 3.99-8 4.01z"/>
                    </svg>
                  )}
                  {social.name === 'twitter' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                      <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.608 1.794-1.574 2.163-2.724-.949.562-2.005.974-3.127 1.194-.896-.952-2.178-1.548-3.594-1.548-2.719 0-4.924 2.206-4.924 4.924 0 .386.045.762.128 1.124-4.092-.205-7.725-2.165-10.157-5.144-.424.728-.666 1.571-.666 2.475 0 1.708.87 3.216 2.188 4.1-.807-.026-1.566-.248-2.229-.616v.061c0 2.385 1.698 4.374 3.946 4.828-.413.112-.849.172-1.296.172-.317 0-.626-.031-.929-.089.627 1.956 2.444 3.379 4.597 3.419-1.68 1.319-3.8 2.105-6.104 2.105-.396 0-.788-.023-1.174-.067 2.179 1.397 4.768 2.211 7.548 2.211 9.054 0 14-7.496 14-13.986 0-.21 0-.423-.015-.634.961-.693 1.796-1.562 2.457-2.549z"/>
                    </svg>
                  )}
                  {social.name === 'linkedin' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                      <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.28c-.966 0-1.75-.79-1.75-1.75s.784-1.75 1.75-1.75 1.75.79 1.75 1.75-.784 1.75-1.75 1.75zm13.5 11.28h-3v-5.6c0-3.36-4-3.12-4 0v5.6h-3v-10h3v1.4c1.396-2.586 7-2.777 7 2.48v6.12z"/>
                    </svg>                  
                  )}
                </a>
              )
            ))}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SPFooter;
