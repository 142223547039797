import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import '../../styles/AboutUs.css'; // Import the CSS file for styling
import Api from "../../api/api";
import apiUtil from '../../api/apiUtil';
import Maintain from '../../assets/undermaintain.png';
import "../../styles/ContentPage.css"

const ContentPage = () => {
  const location = useLocation();
  // const { id } = useParams();
  const { id } = location.state || 4; // fallback in case state is undefined
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState({});

  useEffect(() => {
    if (id) {
      callReadApi();
    }
  }, [id]);

  const callReadApi = useCallback(() => {
    setIsLoading(true);
    const getData = Api.details('tab', id);
    
    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataObj = data.data ? data.data : {};
        setTab(dataObj);
        setIsLoading(false);
      }, (error, type) => {
        setTab({});
        setIsLoading(false);
      });
    });
  }, [id]);

  return (
    <Container className="content-container my-5">
      {isLoading ? (
        <p style={{height: '300px'}}>Loading...</p>
      ) : (
        tab.content ? (
          <div dangerouslySetInnerHTML={{ __html: tab.content }} />
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <img src={Maintain} alt="Default Content" className='w-50'/>
          </div>
        )
      )}
    </Container>
  );
};

export default ContentPage;
