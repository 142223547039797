import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cartActions } from '../redux-state/CartState';
import { Link } from 'react-router-dom';
import BreadCrumb from './BreadCrumb';
import "../styles/SinglePage.css";
import { AiFillStar } from "react-icons/ai";
import SinglePageFAQ from './SinglePageFAQ';
import YouMayAlsoLike from './YouMayAlsoLike';
import AutoPlayMethods from './SingleAlsoLike';
import JournalSection from "./JournalSection";
import SearchBox from './SearchBox';
import Review1 from './Review1';
import better from "../assets/better.jpeg";
import { FaShippingFast, FaLock } from "react-icons/fa";
import { BsCurrencyDollar } from "react-icons/bs";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { useToast } from '@chakra-ui/react';
import SPFooter from './SPFooter';
import Api from "../api/api";
import apiUtil from '../api/apiUtil';
import Marquee from "react-fast-marquee";
import Review from './Review';
import { useCompany } from '../contexts/CompanyContext';

const SinglePage = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const { companyId, companyData } = useCompany();

  const [product, setProduct] = useState({});
  const [activeImg, setActiveImg] = useState('');
  const [imgChange, setImgChange] = useState(false);
  const [review, setReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [discount, setDiscount] = useState(null);


  useEffect(() => {
    callReadApi();
  }, []);

  const callReadApi = useCallback(() => {
    
      setIsLoading(true);
      const getData = Api.details('products', id + `?company_id=${companyId}`)
      
      Promise.all([getData]).then( (results) => {
          apiUtil.parseResult(results[0], (data) => {
            const dataArr = data.data ? data.data : []
            const discountData = data.discount || null;

            const discountedPrice = calculateDiscountPrice(dataArr.price, discountData);

            const transformedData = {
                id: dataArr.id,
                name: dataArr.name,
                reviews_avg_rating: dataArr.reviews_avg_rating,
                reviews_count: dataArr.reviews_count,
                price: discountedPrice, // Using discounted price instead of original price
                originalPrice: dataArr.price,
                type: 'ourBestSellers', // example logic for type
                primaryImage: dataArr.image_url,
                hoverImg: dataArr?.images?.length > 0 ? dataArr.images[0].file_url : dataArr.image_url,
                gallery_images: dataArr.images,
                description: dataArr.description,
                how_to_use: dataArr.how_to_use,
                quantity: dataArr.quantity,
                stripeDiscountId: discountData ? discountData.stripe_coupon_id : null, // For Discount
              };

            setProduct(transformedData);
            setDiscount(discountData);
            setIsLoading(false);

          }, (error, type) => {
            setProduct([]);
            setIsLoading(false);
          });
      });
  }, [])

  const calculateDiscountPrice = (price, discountData) => {
      if (!discountData) return price;

      const { discount_type, amount } = discountData;

      // Calculate based on discount type (e.g., 'percent' or 'fixed')
      if (discount_type === 'percent') {
          return price - (price * amount) / 100;
      } else if (discount_type === 'fixed') {
          return price - amount;
      }

      return price; // If no discount type matches, return the original price
  };

  const addItemToCartHandler = () => {
    dispatch(cartActions.addItemToCart({
      id: product.id,
      price: product.price,
      originalPrice: product.originalPrice,
      title: product.name,
      image: product.primaryImage,
      stripeDiscountId: product.stripeDiscountId,
    }));
    toast({
      title: '',
      description: "Successfully Added",
      status: 'success',
      duration: 1500,
      isClosable: true,
    });
  };

  const imgSrcTarget = (e) => {
    setActiveImg(e.target.src);
    setImgChange(true);
  };

  const defaultImageSrc = () => {
    setActiveImg(product.primaryImage);
    setImgChange(false);
  };

  const numOfRev = Math.floor(Math.random() * 5) + 1;
  const reviews = Math.floor(Math.random() * 900) + 100;

  return (<>
    <div className='container'>
      <div className="mb-4">
        <BreadCrumb name={product.name} />
      </div>
      <div className='row mt-4 pt-4'>
        <div className='col-12 col-lg-6 pt-4 mt-4 d-flex flex-column align-items-center'>
          <figure className='relative'>
            <img src={imgChange ? activeImg : product.primaryImage} className="img-fluid cursor-pointer object-cover singlePageMainPic border rounded" alt={product.name} style={{width: '400px', height: '400px', objectFit: 'cover'}}/>
          </figure>
          <div className='d-flex gap-2 mt-4 w-100 px-4' style={{overflowX: 'hidden', overflowY : 'hidden'}}>
          <Marquee pauseOnHover={true} speed={100} >
            {product.gallery_images && product.gallery_images.map((img, index) => (
              <img key={index} src={img.file_url} className="img-thumbnail cursor-pointer mx-4" style={{width: '200px', height: '200px', objectFit: 'cover'}} onMouseEnter={imgSrcTarget} onMouseLeave={defaultImageSrc} alt={product.name} />
            ))}
        </Marquee>
            
          </div>
        </div>
        <div className='col-12 col-lg-6 pt-4 mt-4'>
          <div className='d-flex flex-column gap-3'>
            <p className='fw-bold fs-1'>{product.name}</p>

            {product.originalPrice && product.originalPrice !== product.price ? (
                <>
                    <p className='fs-2'>
                      <del>${parseFloat(product.originalPrice).toFixed(2)}</del>
                    </p>
                    <p className='fs-4 text-success'>${parseFloat(product.price).toFixed(2)}</p>
                </>
            ) : (
                <p className='fs-2'>${parseFloat(product.price).toFixed(2)}</p>
            )}
          </div>

          <hr />
          <div className=' gap-2 flex starHold'>
            {product.reviews_avg_rating && [...Array(Math.floor(parseFloat(product.reviews_avg_rating)))].map((_, index) => (
              <AiFillStar key={index} />
            ))}
            <p className='font-semibold l1rem'>{product.reviews_count} reviews</p>
          </div>
          {/* <p className='font-semibold text-md loyalty'>YOU CAN EARN 50 LOYALTY POINTS</p> */}
          <div className='spBtnHold d-flex gap-2'>
            <button className='spAddTOCart mb-2' style={{backgroundColor: companyData && companyData.secondary_color, borderColor: companyData && companyData.secondary_color}} onClick={addItemToCartHandler}>ADD TO CART</button>
            <button className='spAddTOCart mb-2' style={{backgroundColor: companyData && companyData.secondary_color, borderColor: companyData && companyData.secondary_color}} onClick={()=> setReview(!review)}>Review</button>
          </div>
         {review && <div className='w-100 mt-4' >
            <Review id={product.id}/>
          </div>}
          <div className='w-100 mt-4'>
            <SinglePageFAQ description={product.description} how={product.how_to_use} />
          </div>
        </div>
        
      </div>
      <p className='fw-bold fs-1 mt-4 pt-4 text-center'>YOU MAY ALSO LIKE</p>
      <YouMayAlsoLike companyId={companyId} />
      <Review1 starCalc={product.reviews_avg_rating} rev={product.reviews_count} />
      <SearchBox id={id}/>
   
      
    </div>
      
    {/* <div className='d-flex justify-content-evenly align-items-center p-4'>
      <div className='d-flex justify-content-between align-items-center'><FaShippingFast className='w-16 h-20 pe-4' /><div className='fw-bold fs-6'>2 DAY DELIVERY</div></div>
      <div className='d-flex justify-content-between align-items-center'><FaLock className='w-12 h-16 pe-4' /><div className='fw-bold fs-6'>secure checkout</div></div>
      <div className='d-flex justify-content-between align-items-center'><BsCurrencyDollar className='w-16 h-20 pe-4' /> <div className='fw-bold fs-6'>royalty points</div></div>
      <div className='d-flex justify-content-between align-items-center'><GiCardboardBoxClosed className='w-16 h-20 pe-4' /><div className='fw-bold fs-6'>easy returns</div></div>
    </div> */}
    <div className='spfooterHold'>
        <SPFooter />
      </div>
    </>
  );
};

export default SinglePage;
